import React, { useState } from 'react';
import axios from 'axios';

export const sendEmail = async (to, subject, text) => {
    try {
        const response = await axios.post('http://backend:3001/send-email', {
            to,
            subject,
            text
        });
        
        return response
    } catch (error) {
        console.error('Error sending email:', error);
    }
};